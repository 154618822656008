import React from "react";
import Navbar from "../sections/Navbar/Navbar";
import ImgContentTemplate from "../components/old/ImgContentTemplate/ImgContentTemplate";
// @ts-ignore
import GorgiasImg from "../assets/img/old/gorgias.png";
// @ts-ignore
import GorgiasStudyImg from "../assets/img/old/gorgiasStudy.png";
// @ts-ignore
import GorgiasLogo from "../assets/img/old/gorgiasLogo.png";
// @ts-ignore
import locationIcon from "../assets/img/old/icons/majesticons_map-marker-line.png";

// @ts-ignore
import userIcon from "../assets/img/old/icons/majesticons_users-line.png";

// @ts-ignore
import buildingIcon from "../assets/img/old/icons/icon-park-outline_factory-building.png";

// @ts-ignore
import l1Icon from "../assets/img/old/icons/icon-park-outline_setting.png";

// @ts-ignore
import l2Icon from "../assets/img/old/icons/icon-park-outline_setting-three.png";

// @ts-ignore
import l3Icon from "../assets/img/old/icons/icon-park-outline_tree-one.png";

// @ts-ignore
import l4Icon from "../assets/img/old/icons/icon-park-outline_oval-love-two.png";

// @ts-ignore
import l5Icon from "../assets/img/old/icons/icon-park-outline_user.png";

// @ts-ignore
import l6Icon from "../assets/img/old/icons/icon-park-outline_brain.png";

// @ts-ignore
import Seo from "../utils/seo";
import CompanyIconCard from "../components/old/CompanyIconCard/CompanyIconCard";
import Objectives from "../sections/old/Objectives/Objectives";
import CompanyCaseStudyTemplate from "../components/old/CompanyCaseStudyTemplate/CompanyCaseStudyTemplate";
import What from "../sections/old/What/What";
import ProfileVideoCard from "../components/old/ProfileVideoCard/ProfileVideoCard";
// @ts-ignore
import adelineThumb from "../assets/img/old/thumbnails/adeline.png";
// @ts-ignore
import playIcon from "../assets/img/old/icons/playButton.svg";
import ReactPlayer from "react-player";
import { Link } from "gatsby";
import Footer from "../sections/Footer";
// @ts-ignore
import Layout from "../layouts";

const Gorgias = () => {
  return (
    <>
      <Seo title="Gorgias | Simplifiez votre gestion du service client" description="Optimisez votre support client avec Gorgias. Découvrez comment améliorer l'expérience utilisateur et fidéliser vos clients."/>
      <Layout>
        <ImgContentTemplate
          img={GorgiasImg}
          title="Gorgias"
          des={
            <>
              <p>
                Gorgias fournit un service d'assistance multicanal mondial aux commerçants
                commerce électronique. Il permet aux marchands de gérer l'ensemble de leur assistance en
                d'un seul endroit. L'entreprise s'est développée rapidement au cours des dernières années et est devenue une organisation de plus de 300 personnes aidant plus de 10 000 personnes.
                300 personnes qui aident plus de 10 000 clients !
                clients !
              </p>
              <div className="flex justify-between py-4">
                <CompanyIconCard
                  icon={locationIcon}
                  header={"San Francisco, California"}
                  des={"Localisation"}
                />
                <CompanyIconCard
                  icon={userIcon}
                  header={"300+ \n" + "(Télécommande complète)"}
                  des={"Taille de l'entreprise"}
                />
                <CompanyIconCard
                  icon={buildingIcon}
                  header={"L'industrie"}
                  des={"Commerce électronique"}
                />
              </div>

              <p>
                En 2021, Gorgias a consulté Coachello pour ses défis liés à l'hypercroissance.
                de l'hypercroissance ; les employés étaient de plus en plus stressés
                stress et se dirigeaient potentiellement vers l'épuisement professionnel. Ces défis se traduisaient par une baisse de la productivité, des conflits et des problèmes de santé.
                Ces défis se traduisaient par une baisse de la productivité, des conflits et une lassitude générale.
                conflits et une lassitude générale. Gorgias avait besoin de trouver un programme qui
                qui aiderait à développer le bien-être de ses employés tout en encourageant l'auto
                l'auto-développement et la communication entre le siège et les différents bureaux à
                entre le siège et les différents bureaux à l'échelle mondiale.
              </p>
            </>
          }
        />
        <Objectives header="Objectifs">
          <div>
            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
              <li className="flex gap-2 items-center m-0">
                <img width={32} height={32} src={l1Icon} />
                <h4 className={"m-0"}>Augmenter les performances</h4>
              </li>
              <li className="flex gap-2 items-center m-0">
                <img width={32} height={32} src={l2Icon} />
                <h4> Augmenter le présentéisme </h4>
              </li>
              <li className="flex gap-2 items-center m-0">
                <img width={32} height={32} src={l3Icon} />
                <h4> Augmenter la résilience </h4>
              </li>
              <li className="flex gap-2 items-center m-0">
                <img width={32} height={32} src={l4Icon} />
                <h4> Augmenter le bien-être </h4>
              </li>
              <li className="flex gap-2 items-center m-0">
                <img width={32} height={32} src={l5Icon} />
                <h4>Accroître le développement personnel </h4>
              </li>
              <li className="flex gap-2 items-center m-0">
                <img width={32} height={32} src={l6Icon} />
                <h4> Améliorer la force mentale </h4>
              </li>
            </ul>
          </div>
        </Objectives>
        <CompanyCaseStudyTemplate
          img={GorgiasStudyImg}
          logo={GorgiasLogo}
          header="Comment Gorgias a commencé à créer une culture de coaching à grande échelle avec
Coachello à la demande "
          rightContentE={
            <>
              <p
                style={{ letterSpacing: "0.28em" }}
                className="text-[#FD0054] text-[18px] uppercase"
              >
                Solution
              </p>
              <p>
                Heureusement, Adeline Bodemer, responsable des ressources humaines chez Gorgias, avait déjà
                avait déjà fait l'expérience du coaching professionnel et savait qu'il changerait la donne pour ses employés.
                pour ses employés. Elle souhaitait une solution flexible, adaptée à la culture de Gorgias, qui réponde aux divers besoins de développement de ses employés.
                à la culture de Gorgias, répondre aux divers besoins de développement de leurs employés, faciliter l'engagement avec les coachs et permettre une approche globale de l'entreprise.
                nombreux employés, faciliter l'engagement avec les coachs et permettre un
                taux d'adoption élevé. Il était également important que les employés puissent utiliser
                leur allocation de développement professionnel.
              </p>
              <p>
                Nous avons proposé à Gorgias une version personnalisée de notre solution de coaching en tant qu'avantage pour l'ensemble de l'organisation.
                de notre solution de coaching en tant qu'avantage, à l'échelle de l'organisation. L'objectif était de rendre le coaching
                disponible pour tous les talents, avec un pool de coachs certifiés qui
                correspondent à la diversité des talents de Gorgias en termes de nationalités, de langues, de fuseaux horaires, de hiérarchie, etc,
                langues, fuseau horaire, hiérarchie, etc.{" "}
              </p>

              <br />
              <p
                style={{ letterSpacing: "0.28em" }}
                className="text-[#FD0054] text-[18px] uppercase"
              >
                Caractéristiques
              </p>

              <ul className="leading-6">
                <li>L'application Slack de Coachello déployée pour tous les employés</li>
                <li>
                  Accès 24/7 à des coachs certifiés dans le monde entier qui correspondent à la diversité des talents de Gorgias (plus de 25 nationalités).
                  la diversité des talents de Gorgias (plus de 25 nationalités) en termes de culture, de langue, de fuseau horaire, de hiérarchie, etc.
                  culture, langues, fuseau horaire, hiérarchie, etc.
                </li>
                <li>
                  Sensibilisation par le biais de webinaires à la demande pour répondre aux sujets brûlants
                  dans l'entreprise (prévention de l'épuisement professionnel, routine matinale, gestion de l'énergie et compréhension du stress organisationnel).
                  l'énergie et comprendre le stress organisationnel)
                </li>
                <li>
                  Évaluations de coaching, ligne de discussion confidentielle dans Slack et
                  appels de recommandation
                </li>
                <li>Informations sur les données</li>
              </ul>

              <br />

              <p
                style={{ letterSpacing: "0.28em" }}
                className="text-[#FD0054] text-[18px] uppercase"
              >
                Résultats
              </p>
              <p>
                Après un an, <b>la culture du coaching est forte chez Gorgias</b> et fait partie de la culture d'entreprise.
                fait partie de la culture de l'entreprise. La mise en œuvre de cette culture de coaching
                a permis d'obtenir les résultats suivants :
              </p>
              <p>
                Avec une adoption de <b>50%</b> la première année et une <b>moyenne de 5 sessions par personne</b> qu'ils peuvent utiliser chaque fois qu'ils en ont le plus besoin, nous avons aidé les talents de Gorgias à améliorer leurs <b>problèmes de gestion du temps, les problèmes organisationnels, le développement professionnel,</b> et à <b>prévenir certains risques d'épuisement professionnel.</b>
              </p>
              <p>
                En moyenne, près de <b>70%</b> des employés de Gorgias ont remarqué une réduction du stress et <b>40%</b> ont ressenti une augmentation de sentiments positifs tels que l'optimisme et l'espoir. <b>80% des utilisateurs</b> signalent également une <b>amélioration de la productivité</b>. Enfin, les niveaux de <b>satisfaction des coachs</b> dépassent <b>95%</b>.
              </p>

              <br />
              <Link
                target="_blank"
                to={"https://meetings.hubspot.com/quentin-bouche/intro-coachello"}
              >
                <button className="Navbar-button">
                  Apprenez en 20 minutes comment lancer le coaching pour tous les membres de votre entreprise
                  entreprise
                </button>
              </Link>
            </>
          }
        />

        <div className="text-center rounded p-8 sm:p-24 bg-[#FDF4EA]">
          <div className="w-full md:w-1/2  m-auto ">
            <p className="h1-red">Ce que disent nos clients</p>
            <h2>"Des progrès incroyables</h2>
            <ReactPlayer
              controls={true}
              // light={adelineThumb}
              playing
              width="100%"
              url="https://www.youtube.com/watch?v=s8p-gsnM-hs&t=1s"
              playIcon={<img src={playIcon} />}
            />
            <div className={"bg-white flex-wrap p-5 flex gap-3 justify-between"}>
              <div>
                <h4 className={"m-0"}>Adeline Bodemer</h4>
                <h5 className={"m-2"}>Chef du personnel</h5>
              </div>
              <div>
                <h4 className={"m-0"}>Emna Charfi</h4>
                <h5 className={"m-2"}>Succès des clients</h5>
              </div>
              <div>
                <h4 className={"m-0"}>Astaeka Pramuditya</h4>
                <h5 className={"m-2"}>Marketing Client</h5>
              </div>
            </div>
          </div>
        </div>
        <What />
      </Layout>
    </>
  );
};

export default Gorgias;
